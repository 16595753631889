<template>
  <div class="system-setting">
    <a-steps direction="vertical">
      <a-step status="wait">
        <template #title>
          <div>
            管理员登录 <a-button type="link"
                      target="_blank"
                      href="https://work.weixin.qq.com/wework_admin/frame#index">
              企业微信后台
            </a-button>，找到菜单「学员联系」-「学员」，展开API，找到“微信开发者ID”项，点击“绑定”。
          </div>

          <a-image class="my-12"
                   style="width: 700px;"
                   :src="require('@/assets/corpWechatConfig.png')"
                   alt="企微公众号后台配置示意图" />
        </template>
        <template #description>
          注：非教育机构管理后台的菜单名称为「客户联系」 - 「客户」
        </template>

      </a-step>
      <a-step>
        <template #title>
          系统跳转到公众平台帐号授权页面，使用公众平台绑定的管理员<span class="text-danger">个人微信号</span>扫描授权。
        </template>
      </a-step>
      <a-step>
        <template #title>
          完成配置后，点击右侧“完成配置”按钮。
          <a-button :disabled="status || wechatAccountStatus"
                    type="primary"
                    @click="getWechatAccountStatus">
            {{ status || wechatAccountStatus ? '已配置' : '完成配置' }}
          </a-button>
        </template>
      </a-step>
    </a-steps>

  </div>
</template>

<script>
import { Steps, Image, message } from "ant-design-vue";

import { ref } from "vue";

import corpSettingApi from "@/service/api/corpSetting";

export default {
  name: "OfficialAccountWechat",

  props: {
    status: Boolean,
  },

  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    AImage: Image,
  },

  setup() {
    const wechatAccountStatus = ref(false);
    const getWechatAccountStatus = async () => {
      const res = await corpSettingApi.getBindWechatAccountStatus();
      wechatAccountStatus.value = res.setBindWechatAccount;

      if (!res) {
        message.error("尚未完成配置，请重试");
      }
    };

    getWechatAccountStatus();

    return {
      wechatAccountStatus,
      getWechatAccountStatus,
    };
  },
};
</script>
<style lang="less" scoped>
.system-setting .ant-steps-item-wait {
  min-height: 100px;
}
.ant-input {
  width: 320px;
}
.save-btn {
  position: absolute;
  left: 50%;
  bottom: 32px;
  margin-left: -62px;
}
</style>
